<template>
  <h1>Registrierung</h1>
  <HelloWorld/>
  <ul>
    <li><a href="https://element-plus.org/#/en-US">https://element-plus.org/#/en-US</a></li>
    <li><a href="https://gitlab/manuel.timelthaler/element-plus">https://gitlab/manuel.timelthaler/element-plus</a>
    </li>
  </ul>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>
