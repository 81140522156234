import { createApp } from 'vue'
import { ElForm, ElFormItem, ElInput, ElSelect, ElOption, ElCheckbox, ElButton } from 'element-plus';
import './main.scss';
import App from './App.vue';

const app = createApp(App)
app.component(ElForm.name, ElForm);
app.component(ElFormItem.name, ElFormItem);
app.component(ElInput.name, ElInput);
app.component(ElSelect.name, ElSelect);
app.component(ElOption.name, ElOption);
app.component(ElCheckbox.name, ElCheckbox);
app.component(ElButton.name, ElButton);
app.mount('#app')
