<template>
  <el-form action="/register" method="POST" ref="registrationForm" :rules="rules" :model="form">
    <el-form-item label="Eingabefeld" prop="inputField">
      <el-input v-model="form.inputField"></el-input>
    </el-form-item>
    <el-form-item label="Branchenauswahl">
      <el-select class="u-fullWidth" v-model="form.multipleSelect" multiple>
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="form.checkbox">Ich bin kein Personaldienstleister</el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('registrationForm')">Button</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  data() {
    return {
      form: {
        inputField: '',
        multipleSelect: [],
        checkbox: false,
      },
      rules: {
        inputField: [
          { required: true, message: 'Please enter something into this field', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    options() {
      return [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }];
    },
  },
  methods: {
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$refs[form].$el.submit();
        } else {
          console.error('Fehler!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss">
.u-fullWidth {
  width: 100%;
}

// Styles taken from `.k-c-pill` in Global Styles
// Less strict than our BEM but easy to work with!
.el-select .el-select__tags .el-tag {
  border-color: #edeff1;
  background-color: #f5f7f7;
  color: #142542;
}
</style>
